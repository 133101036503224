html{
  margin: 0;
  padding: 0;
  background-color: #ccc;
  height: 100%;
  overflow: hidden;
}

body{
  overflow: hidden;
}

body[data-theme='light']{
  --bg: #fff;
  --cl: #484848;
  --strong-font: black;
}

body[data-theme='dark']{
  --bg: rgb(30, 30, 30);
  --cl: #fff;
  --strong-font: #E8E8E8;
}

.div-edit{
  background-color: var(--bg);
  color: var(--strong-font);
  min-height: 50px;
  padding: 5px;
  margin-bottom: 10px;
  overflow: auto;
  border: teal 1px solid;
}

.container-fluid{
  box-sizing: border-box;
  width: 100%;
}

.container {
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  /*max-height: calc(100% - 50px);*/
}

button:focus {outline:0;}

.separator {
  display: flex;
  border-bottom: black 2px solid;
  width: 100%;
  height: 0;
}

main {
  height: 90vh;
  overflow: auto;
}

.animate-bottom{
  position:relative;
  animation:animatebottom 0.4s
}
@keyframes animatebottom{from{bottom:-300px;opacity:0} to{bottom:0px;opacity:1}}

footer{
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: teal;
  color: white;
  height: 6%;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.overlay{
  position:   fixed;
  opacity:    0.8;
  background: rgba(255,255,255,.5);
  width:      100%;
  height:     100%;
  z-index:    1;
  top:        0;
  left:       0;
  transition: 0.3s;
}

.check{
  color: chartreuse;
}

.cancel{
  color: indianred;
}

.teal{
  color: teal;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
